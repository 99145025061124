import { Color } from 'components';
import { MoreInfoPopup } from '../MoreInfoPopup';
import { setItem, useDeleteProjectBookmarkByProjectIdMutation, useDeleteProjectBookmarkByProjectIdFromBookmarkDrawerMutation, useCreateProjectBookmarkMutation, setSelectedItemDetailsOnMap, setShowParcelsTypeValue } from 'app/services';
import { Badge, Drawer, notification } from 'antd';
import { useDispatch } from 'react-redux';
import BookmarkPopover from 'components/BookmarkPopover';
import { useEffect, useState } from 'react';
import { ViewShowParcelsQueryContent } from 'views/MapPage/MainFilterDrawer/MatchedResultBox/ViewShowParcelsQueryContent';

// import { Counties } from './CountyInfo';

export interface ChildProjectCardProps {
  id?: string;
  title: string;
  titleColor: Color;
  data: { name: any; value: any | undefined }[];
  showMore?: boolean;
  allData?: any;
  information?: any;
  parcelId?: string;
  from?: string;
  showBookmark?: boolean;
  id_serial?: any;
  item?: any;
  showParcelsLink?: any;
  setProjectId?: (value: any) => any;
  onParenChildBadgeClick?: (item: any, type: any) => void;
  onClickResultCard?: () => void;
  setCurrentPage?: (value: any) => any;
  closeDrawer?: () => void;
  onBookMarkIconClick?: (item: any, type: any) => void;
  refetchParcels?: () => void;
  showChildProjectsLink?: any;
}

export const ChildProjectCard = ({
  id,
  title,
  titleColor,
  data,
  information,
  showMore,
  allData,
  showBookmark,
  id_serial,
  item,
  from,
  showParcelsLink,
  setProjectId,
  onParenChildBadgeClick,
  onClickResultCard,
  setCurrentPage,
  closeDrawer,
  onBookMarkIconClick,
  refetchParcels,
  showChildProjectsLink
}: ChildProjectCardProps) => {
  const dispatch = useDispatch();
  const [createProjectBookmark] = useCreateProjectBookmarkMutation();
  const [deleteProjectBookmark] = useDeleteProjectBookmarkByProjectIdMutation();

  const [deleteProjectBookmarkDrawer] = useDeleteProjectBookmarkByProjectIdFromBookmarkDrawerMutation();

  const [showParcelsQuery, setShowParcelsQuery] = useState(false);

  const [isAdmin, setIsAdmin] = useState<any>(false)

  useEffect(() => {
    const userDetails = localStorage.getItem('user')
    if (userDetails && JSON.parse(userDetails)?.role && JSON.parse(userDetails)?.role === 'admin') {
        setIsAdmin(true)
    }
}, [])

  const onClose = () => {
    setShowParcelsQuery(false);
  };


  const deleteBookmark = async (isBookmarkDrawer = false) => {
    let projectId, dataArgs;

    if (title === 'Project') {
      projectId = id;
      dataArgs = allData?.item?.dataArgs || allData?.dataArgs;
    } else if (title === 'Projects') {
      projectId = allData?.item?.id;
      dataArgs = allData?.item?.dataArgs;
    } else {
      return; // Exit the function if the title is neither 'Project' nor 'Projects'
    }

    const deleteFunction = isBookmarkDrawer ? deleteProjectBookmarkDrawer : deleteProjectBookmark;

    await deleteFunction({ id: projectId, dataArgs });

    if (onBookMarkIconClick) {
      onBookMarkIconClick(allData, 'remove');
    }

    notification.success({
      placement: 'topRight',
      message: `Bookmark deleted successfully!`,
    });

    if (title === 'Projects' && refetchParcels) {
      refetchParcels();
    }

    if (setCurrentPage) {
      setCurrentPage(1);
    }
  };
  /**
   * Sets the selected parent project details and triggers actions to update map display.
   * Dispatches Redux actions to set selected item details and updates local storage flags.
   * Optionally triggers a callback function upon execution.
   *
   * @returns {void}
   */
  const showParentProjectonMap = () => {
    dispatch(
      setItem({
        id: allData?.item?.id,
        type: allData?.type,
        parent_project_id: allData?.parent_project_id,
      })
    );
    localStorage.setItem('selectedProjectType', '')
    localStorage.setItem('showingChildProjectOnMap', 'false')
    window.dispatchEvent(new CustomEvent("showingChildProjectOnMap"));
    if (onClickResultCard) {
      onClickResultCard()
    }
  }
  /**
   * Creates a bookmark for a project or projects list based on the `title` and `allData`.
   * Determines the `projectId` and `dataArgs` based on the `title` and `allData` properties.
   * Sends a request to create a bookmark with the appropriate parameters and handles success actions.
   *
   * @returns {void}
   */
  const createBookmark = () => {
    let projectId, dataArgs;

    if (title === 'Project' && allData?.id) {
      projectId = allData.id;
      dataArgs = allData.dataArgs;
    } else if (title === 'Project' && allData?.item?.id) {
      projectId = allData.item.id;
      dataArgs = allData.item.dataArgs;
    } else if (title === 'Projects' && allData?.item?.id) {
      projectId = allData.item.id;
      dataArgs = allData.item.dataArgs;
    } else {
      return; // If none of the conditions match, exit the function
    }
    const bookmarkType = allData?.parent_project_id || allData?.item?.parent_project_id ? 'child' : 'parent';

    createProjectBookmark({ project_id: projectId, dataArgs: dataArgs, type: bookmarkType, survey_taker: localStorage.getItem('surveyEmail') })
      .finally(() => {
        if (onBookMarkIconClick) {
          onBookMarkIconClick(allData, 'create');
        }
        notification.success({
          placement: 'topRight',
          message: `${title} bookmarked successfully!`,
        });
        if (refetchParcels) {
          refetchParcels();
        }
      });
  }
  /**
   * Component for rendering a bookmark button based on the context (`from` prop).
   * Displays a filled bookmark icon with a popover to remove bookmark if `existsInBookmarks` is true.
   * Otherwise, displays an empty bookmark icon with a popover to add bookmark.
   *
   * Uses BookmarkPopover component for confirmation dialogs and icons for visual representation.
   */
  function BookmarkButton() {
    return (
      <span>
        {from === 'child-project' && allData?.existsInBookmarks ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Remove Bookmark'
            onConfirm={() => deleteBookmark()}
            body={<img
              title='Remove Bookmark'
              src='bookmark_filled.svg'
              alt='bookmark-filled'
              style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
            />}
          />
        ) : from === 'child-project' ? (
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title='Add Bookmark'
            onConfirm={createBookmark}
            body={<img
              title='Add Bookmark'
              src='bookmark.svg'
              alt='bookmark'
              style={{ cursor: 'pointer', height: 15, width: 15, marginTop: 4 }}
            />}
          />

        ) : null}
      </span>
    );
  }
  /**
   * Component for displaying meta details and actions related to a project or item.
   * Renders title, bookmark button, map view icon, parcels links, meta details, and more info popup.
   */
  return (
    <div className="naxxa-meta-detail">
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={`meta-title ${titleColor}`}>{title}
          <BookmarkButton />
        </div>
        <div onClick={(e: any) => {
          if ((allData?.type === 'Projects' || from === 'child-project')) {
            if (!allData?.item?.geom_center?.coordinates && !allData?.item?.geomCenter?.coordinates && !allData?.geom_center?.coordinates) {
              notification.error({
                placement: 'topRight',
                message: 'Center coordinates not available for the selected item',
                duration: 10,
              });
              return;
            }
          }
          if (from === 'child-project') {
            e.stopPropagation();
            showParentProjectonMap();
          }
          else {
            if (onClickResultCard) {
              onClickResultCard()
            }
          }
        }}>
          {from === 'child-project' ?
            <img title='view on map' src='exportoutlined.svg' alt='Export' style={{ cursor: 'pointer', height: 20, width: 15 }} />
            : null}
        </div>
        {showBookmark ?
          <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} onClick={() => deleteBookmark()} />
          : null}
        <>
          {showParcelsLink && (title === 'Project' || title === 'Projects')
            && (information?.parcels?.length || allData?.item?.parcels?.length) && <span className="meta-data-show-parcels"
              onClick={(e) => {
                localStorage.removeItem('projectPolygonCenter')
                localStorage.setItem('showParcelsType', 'all')
                dispatch(setShowParcelsTypeValue('all'))
                if (setProjectId) {
                  e.stopPropagation()
                  if (closeDrawer) {
                    const latLng = {
                      lat: allData?.item?.lat,
                      lng: allData?.item?.lng
                    }
                    localStorage.setItem('projectPolygonCenter', JSON.stringify(latLng))
                    closeDrawer()
                  }
                  setProjectId(allData?.id)
                }
              }}>
              Show Parcels ({(information?.parcels?.length
                || allData?.item?.parcels?.length ||
                allData?.parcels?.length)})
            </span>}
          {showParcelsLink && (title === 'Project' || title === 'Projects')
            && allData?.buildableParcels ? <span title='Show Buildable Parcels' className="meta-data-show-parcels"
              onClick={(e) => {
                localStorage.removeItem('projectPolygonCenter')
                localStorage.setItem('showParcelsType', 'buildableParcels')
                dispatch(setShowParcelsTypeValue('buildableParcels'))
                if (setProjectId) {
                  e.stopPropagation()
                  if (closeDrawer) {
                    const latLng = {
                      lat: allData?.item?.lat,
                      lng: allData?.item?.lng
                    }
                    localStorage.setItem('projectPolygonCenter', JSON.stringify(latLng))
                    closeDrawer()
                  }
                  setProjectId(allData?.id)
                }
              }}>
            <img title='Buildable' src='blue-circle-icon.svg' alt='buildable' style={{ cursor: 'pointer', marginRight: 2, height: 13, width: 13 }} />({allData?.buildableParcels})
          </span> : null}
          {showParcelsLink && (title === 'Project' || title === 'Projects')
            && allData?.alreadyBuildedParcels ? <span title='Show Already Built Parcels' className="meta-data-show-parcels"
              onClick={(e) => {
                localStorage.removeItem('projectPolygonCenter')
                localStorage.setItem('showParcelsType', 'alreadyBuildedParcels')
                dispatch(setShowParcelsTypeValue('alreadyBuildedParcels'))
                if (setProjectId) {
                  e.stopPropagation()
                  if (closeDrawer) {
                    const latLng = {
                      lat: allData?.item?.lat,
                      lng: allData?.item?.lng
                    }
                    localStorage.setItem('projectPolygonCenter', JSON.stringify(latLng))
                    closeDrawer()
                  }
                  setProjectId(allData?.id)
                }
              }}>
            <img title='Already Built' src='red-circle-icon.svg' alt='Already Built' style={{ cursor: 'pointer', marginRight: 2, height: 13, width: 13 }} />({allData?.alreadyBuildedParcels})
          </span> : null}
        </>
        {showBookmark ?
          <img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} onClick={() => deleteBookmark()} />

          // <TagFilled title='Remove Bookmark' style={{ cursor: 'pointer', zIndex: 999999 }} onClick={deleteBookmark() => }/>()
          : null}
      </div>
      {data &&
        data
          .filter(
            (src: { name: string; value: any }) =>
              src.name !== 'County',
          )
          .map(({ name, value }, idx) => {
            if (['Developer', 'Builder', 'Investor', 'Real Estate Agent'].includes(title) && name === 'City') {
              return null
            }
            if (['Projects'].includes(title) && name === 'Acreage') {
              return null
            }
            return (
              <div className="meta-detail" key={idx}>
                {name === 'View Child Projects' && showChildProjectsLink ?
                  <span style={{ zIndex: 999999 }} onClick={(e) => {
                    e.stopPropagation()
                    dispatch(setSelectedItemDetailsOnMap(null))
                    if (onParenChildBadgeClick) {
                      onParenChildBadgeClick(item, 'parent')
                    }
                    dispatch(setItem({
                      id: item?.item?.id
                    }))
                  }}>
                    <Badge style={{
                      backgroundColor: '#1677ff',
                      lineHeight: '20px'
                    }} count={name} /> </span> :
                  null}
                {name === ('View Parent Projects') && showChildProjectsLink ?
                  <span style={{ zIndex: 999999 }} onClick={(e) => {
                    e.stopPropagation()
                    dispatch(setSelectedItemDetailsOnMap(null))
                    dispatch(setItem({
                      id: item?.item?.id
                    }))
                    if (onParenChildBadgeClick) {
                      onParenChildBadgeClick(item, 'child')
                    }
                  }} >
                    <Badge style={{
                      backgroundColor: '#1677ff',
                      lineHeight: '20px'
                    }} count={name} /> </span> : (name !== 'View Child Projects' && name !== 'View Parent Projects') ?
                    <span className="meta-detail-name">{name}: </span> : null
                }
                <span className='meta-detail-value-div'>
                  <span className="meta-detail-value"> {name === 'Email' && Array.isArray(value) ?
                    value.map((elem: any, i: any) => {
                      return i > 0 ? <>, <a key={i} href={'mailto:' + elem}>{elem}</a></> : <a key={i} href={'mailto:' + elem}>{elem}</a>
                    }) : name === 'Email' && value ? <a href={'mailto:' + value}>{value}</a> : value}
                  </span>
                  {name === 'Name' && showParcelsLink && isAdmin ?
                    <span className='meta-data-view-query' onClick={() => {
                      setShowParcelsQuery(true)
                    }}>View Query</span> : null
                  }
                </span>

              </div>
            )
          })}
      {showMore && title === 'Project' &&
        <div className="popup-more-info">
          <MoreInfoPopup title={title} information={information}
            allData={allData} id_serial={id_serial} />
        </div>
      }

      <Drawer
        title={`Show Parcels query`}
        placement="right"
        size="large"
        width={'80%'}
        onClose={onClose}
        open={showParcelsQuery}
        destroyOnClose
        style={{ zIndex: 2000 }}
      >
        <ViewShowParcelsQueryContent count={{ buildableParcels: allData?.buildableParcels || 0, alreadyBuildedParcels: allData?.alreadyBuildedParcels || 0 }} />
      </Drawer>

    </div>
  );
};
